@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.App {
	font-family: Arial, sans-serif;
	text-align: center;
	padding: 0;
	display: flex;
	height: 100vh;
	flex-direction: column;
}

.banner,
.footer {
	background-color: black;
	color: white;
	padding: 30px 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	font-family: 'Fira Sans', sans-serif;
}

.banner {
	font-size: 24px;
	font-weight: 600;
}

.footer {
	font-size: 14px;
	height: 2vh;
	color: rgb(74, 242, 228);
	font-weight: 500;
}

.navbar a {
	color: white;
	text-decoration: none;
	padding: 5px 10px;
	transition: background-color 0.5s;
	font-size: 16px;
	border-radius: 15px;
}

.navbar a:hover {
	color: rgb(74, 242, 228);
}

.main-logo-text {
	margin-top: 10px;
}

.navbar-links {
	display: flex;
	flex-direction: row;
	margin-top: 15px;
}

.footer a {
	color: white;
	text-decoration: none;
}

.site-title {
	font-size: 44px;
}

.navbar,
.footer a:hover {
	color: rgb(74, 242, 228);
}

.details-panel,
.download-section {
	background-color: rgb(43, 43, 43);
	padding: 30px 15px 30px 15px;
	display: flex;
	justify-content: space-between;
	font-family: 'DM Sans', sans-serif;
	flex-grow: 1;
}

.details-header,
.download-box p,
.description-box-header {
	font-family: 'Fira Sans', sans-serif;
	font-weight: 600;
	color: rgb(74, 242, 228);
}

.details-header {
	font-size: 22px;
}

.details-inset {
	padding: 10px;
	color: white;
	border-radius: 15px;
	font-weight: 300;
	margin-left: 10px;
	margin-right: 10px;
	flex: 1;
	text-align: left;
}

.details-panel-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.download-section {
	position: relative;
	background-image: url('../public/images/dj_bench.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center -360px;
}

.download-section::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	pointer-events: none;
	z-index: 1;
}

.download-section > * {
	position: relative;
	z-index: 2;
}

.download-box,
.description-box {
	flex: 1;
	box-sizing: border-box;
	color: white;
}

.download-header {
	font-size: 30px !important;
	margin-top: 40px;
}

.description-box {
	border-radius: 15px;
}

.description-box-header {
	font-size: 28px;
	margin: 20px 150px 40px 150px;
}

.description-box-text {
	/* margin: 40px 55px 40px 55px; */
	font-family: 'DM Sans', sans-serif;
	font-size: 24px;
	font-weight: 300 !important;
}

.navbar-element {
	margin: 12px 10px 0px 10px;
}

button {
	padding: 20px 35px;
	background-color: rgb(74, 242, 228);
	border: none;
	color: rgb(49, 49, 49);
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.5s;
	font-family: 'DM Sans', sans-serif;
	font-size: 1em;
}

button:hover {
	background-color: rgb(49, 49, 49);
	color: rgb(74, 242, 228);
}

.mac-installer-text {
	font-size: 14px;
	margin-top: 35px;
}

.main-logo {
	fill: white;
	height: 75px;
	width: 75px;
}

.logo-cluster {
	display: flex;
	flex-direction: row;
}

.page-not-found {
	text-align: center;
	padding: 50px;
	color: white;
	background-color: rgb(49, 49, 49);
	height: stretch;
	font-family: 'DM Sans', sans-serif;
}

@media (min-width: 1400px) {
	.details-inset {
		margin-left: 40px;
		margin-right: 40px;
	}
}

@media (max-width: 1200px) {
	.description-box-header {
		margin: 20px 80px 40px 80px;
	}
}

@media (max-width: 900px) {
	.download-section,
	.details-panel {
		flex-direction: column;
	}

	.download-section {
		background-position: center 0px;
	}

	.download-box,
	.description-box {
		width: 100%;
		padding: 5px;
		margin-bottom: 30px;
	}
	a .description-box-text {
		margin-left: 5px;
		margin-right: 5px;
	}

	.description-box-header {
		margin: 20px 20px 40px 20px;
	}

	.details-inset {
		margin-left: 5px;
		margin-right: 5px;
	}

	.icon-span-header {
		font-size: 14px;
		vertical-align: top;
	}

	@media (max-width: 800px) {
		.banner {
			flex-direction: column;
		}
		.logo-cluster {
			align-content: center;
			justify-content: center;
		}
		.navbar-links {
			flex-direction: column;
		}
	}

	@media (max-width: 900px) {
		.details-panel-container {
			display: block;
		}
	}
}
