.feedback-page {
	background-color: rgb(43, 43, 43);
	color: white;
	padding-bottom: 100px;	
}

.feedback-page-header {
	font-size: 30px;
	padding: 30px;
	font-family: 'Fira Sans', sans-serif;
	text-align: left;
	margin-left: 30px;
}

.feedback-page-subheader {
	font-size: 20px;
	padding: 10px;
	font-family: 'Fira Sans', sans-serif;
	text-align: left;
	margin-left: 50px;
	color: rgb(74, 242, 228);
}

.feedback-page-directions-container {
	padding-bottom: 30px;
}

.feedback-page-item {
	text-align: left;
	margin-left: 30px;
	padding: 10px 30px 10px 30px;
	font-family: 'DM Sans', sans-serif;
	margin-right: 40%;
}

.highlighted-text {
	color: rgb(158, 239, 131);
	text-decoration: none;
}

.mail-link {
	font-size: 26px;
}

.highlighted-text:hover {
	color: rgb(74, 242, 228);
}

@media (max-width: 800px) {
	.feedback-page-item,
	.feedback-page-header {
		margin-right: 0%;
		margin-left: 0%;
	}
	.feedback-page-subheader {
		margin-left: 20px;
	}
}
