.features-page {
	background-color: rgb(43, 43, 43);
	color: white;
	padding-bottom: 30px;
}

.features-page-header {
	font-size: 30px;
	font-family: 'Fira Sans', sans-serif;
	text-align: left;
	margin-left: 30px;
}

.features-page-subheader {
	font-size: 20px;
	padding: 10px 0px 10px 0px;
	font-family: 'Fira Sans', sans-serif;
	text-align: left;
	color: rgb(74, 242, 228);
}

.features-page-container,
.features-page-header-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-bottom: 15px;
}

.features-page-feature,
.features-page-feature-main {
	text-align: left;
	padding: 10px 30px 10px 30px;
	font-family: 'DM Sans', sans-serif;
	flex: 1;
	box-sizing: border-box;
}

.features-page-video {
	text-align: left;
	padding: 10px 30px 10px 30px;
	font-family: 'DM Sans', sans-serif;
	flex: 1;
	box-sizing: border-box;
	margin-left: 30px;
}

.features-page-feature-main {
	margin-top: 30px;
}

.features-page-feature {
	margin-left: 30px;
}

.video-element {
	margin-top: 60px;
	margin-right: 30px;
}

.highlighted-text {
	color: rgb(158, 239, 131);
	font-weight: 600;
}

.page-title {
	margin-top: 30px;
}

.main-header {
	color: rgb(74, 242, 228);
}

.serato-link {
	text-decoration: none;
}

.serato-link:hover {
	color: rgb(74, 242, 228);
}

.main-header-text {
	font-size: 20px;
}

.features-page-video {
	margin-right: 40px;
}

@media (max-width: 1000px) {
	.features-page-container {
		flex-direction: column;
	}
	.features-page-video,
	.features-page-feature {
		margin-right: 0px;
	}
}

@media (max-width: 800px) {
	.features-page-feature,
	.features-pages-feature-main {
		max-width: 100%;
	}
}

@media (max-width: 1200px) {
	.features-page-header-container {
		flex-direction: column;
	}
	.video-element {
		margin-top: 30px;
	}
	.page-title {
		margin-top: 0px;
	}
	.features-page-feature {
		padding: 0px 30px 0px 30px;
	}
}
