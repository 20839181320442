.commands-page {
	background-color: rgb(49, 49, 49);
	color: white;
	padding-bottom: 20px;
}

.commands-page-container {
	margin-left: 30px;
	margin-right: 30px;	
	text-align: left;
}

.commands-page-header {
	padding: 20px 10px;
	font-size: 28px;
	font-family: 'Fira Sans', sans-serif;
	font-weight: 500;
	color: rgb(74, 242, 228);
}

.commands-page-text {
	padding: 10px;
	font-family: 'DM Sans', sans-serif;
	font-weight: 300;
	width: 50vw;
}

.commands-highlighted-text {
	color: rgb(158, 239, 131);
}

.command-name-span {
	font-size: 24px;
}

.command-name-description-text {
	margin: 3px 0px 16px 0px;
	font-size: 16px;	
}

@media (max-width: 700px) {
	.commands-page-container {
		margin-left: 5vw;
		margin-right: 5vw;
	}
	.commands-page-text {
		width: auto;
	}
}
