.installation-page {
	background-color: rgb(43, 43, 43);
	color: white;
}

.installation-page-header {
	font-size: 30px;
	padding: 30px 30px 15px 30px;
	font-family: 'Fira Sans', sans-serif;
	text-align: left;
	margin-left: 30px;
	color: rgb(74, 242, 228);
}

.installation-page-directions-step a {
	color: rgb(158, 239, 131);
}

.installation-page-directions-step a:visited {
	color: rgb(158, 239, 131);
}

.installation-page-directions-step,
.installation-page-faq-heading,
.installation-page-faq-text {
	text-align: left;
	margin-left: 30px;
	padding: 10px 30px 10px 30px;
	font-family: 'DM Sans', sans-serif;
	margin-right: 20%;
}

.installation-page-faq-list-container {
	margin-top: 20px;
	margin-bottom: 20px;
}

.installation-page-faq-heading {
	font-size: 20px;
	color: rgb(74, 242, 228);
}

.installation-page-directions-step a:hover {
	color: rgb(74, 242, 228);
}

.installation-page-directions-step img {
	display: block;
	margin-top: 30px;
	height: auto;
}

.highlighted-installation-text {
	color: rgb(158, 239, 131);
	font-weight: 600;
}

.installation-page-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.tab-navigation {
	position: sticky;
	top: 0;
	z-index: 1000;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	gap: 10px;
	margin: 5px 10px;
	background-color: rgb(43, 43, 43);
	padding: 10px 0;
}

.tab-navigation button {
	flex: 1 1 auto;
	padding: 10px 20px;
	border: none;
	background-color: rgb(74, 74, 74);
	color: white;
	cursor: pointer;
	font-family: 'Fira Sans', sans-serif;
	border-radius: 5px;
	transition: background-color 0.3s;
}

.tab-navigation button.active {
	background-color: rgb(74, 242, 228);
	color: rgb(43, 43, 43);
}

.tab-navigation button:hover {
	background-color: rgb(158, 239, 131);
}

.panel-container {
	border-radius: 5px;
	padding: 20px;
	background-color: rgb(30, 30, 30);
}

@media (max-width: 800px) {
	.installation-page-directions-step,
	.installation-page-faq-heading,
	.installation-page-faq-text,
	.installation-page-header {
		margin-right: 0%;
		margin-left: 0%;
	}
}

@media (max-width: 1000px) {
	.tab-navigation button {
		flex: 1 1 30%;
	}
}

@media (max-width: 575px) {
	.installation-page-directions-step img {
		width: 100%;
	}
}
